<template>
    <div>
        <ul class="status_ul flex">
            <li :class="examine_status.title == item.title ? 'active' : ''" v-for="(item, index) in statusUl"
                :key="index" @click="statusShow(item)">
                {{ item.title }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() { },
    data() {
        return {
            statusUl: [{
                id: '',
                title: '全部'
            }, {
                id: 0,
                title: '待审核'
            }, {
                id: 1,
                title: '已审核'
            }, {
                id: 2,
                title: '被退回',
            },
            {
                id: 4,
                title: '暂停中',
            }],
            examine_status: {
                id: '',
                title: '全部'
            },
        }
    },
    methods: {
        statusShow(item) {
            this.examine_status = item
            this.$emit('statusTab', item.id)
        }
    },
}
</script>
<style lang="scss" scoped>
::v-deep .item {
    position: absolute;
    right: -10px;
    top: -11px;
    z-index: 10;

}

::v-deep .el-badge__content {
    background: #ff0000;
}

.status_ul {
    justify-content: space-between;
    li {
        position: relative;
        padding: 0.5rem 0.7rem;
        box-sizing: border-box;
        text-align: center;
        border-radius: 0.42rem;
        background: #ffffff;
        box-shadow: 2px 2px 4px rgba(122, 122, 122, 0.25);
        cursor: pointer;
        color: #108cdd;
        margin-left: .5rem;
    }

    .active {
        background: #108cdd;
        border-radius: 0.42rem;
        color: #fff;
    }
}

.status_num {
    position: absolute;
    right: -0.4rem;
    top: -0.5rem;
    width: 1.3rem;
    text-align: center;
    line-height: 1.3rem;
    background: #ff0000;
    border-radius: 50%;
    color: #fff;
}
</style>